/** @jsx jsx */
import { Box, jsx } from "theme-ui";
import LayoutHome from "../layout/LayoutHome";

const Conditions = () => {
  return (
    <LayoutHome
      seoTitle="Conditions d’utilisation - Donations"
      contentHeaderStyle="contentHeaderText"
      contentHeader={<h3>Conditions d’utilisation</h3>}
    >
      <Box sx={{ maxWidth: "80%", margin: "20px auto", color: "#333" }}>
        <p>1. Introduction</p>
        <p>
          Ce sont les conditions d’utilisation du Site
          www.spiritualityfoundation.org. Ce Site est exploité par ou au nom de
          la Fondation Sahaj Marg Spiritualité, Weinfelden, Suisse, enregistrée
          au registre du commerce suisse sous le numéro CHE-385.034.397. Il
          s’agit d’une fondation sans but lucratif exonérée d’impôts. .
        </p>
        <p>
          Votre utilisation du Site sera soumise à ces conditions auxquelles
          vous acceptez par là même d’être lié. L’utilisation de vos
          informations personnelles soumises sur ou via le Site est régie par
          notre Politique de Confidentialité et des Cookies.
        </p>
        <p>
          Nous nous réservons le droit d’ajuster ces conditions lorsque
          nécessaire et d’apporter ces modifications sur le Site.
        </p>
        <p>
          Les présentes conditions d’utilisation sont conformes à la
          Réglementation Générale de l’Union Européenne en Matière de Protection
          des Données (RGPD) entrée en vigueur le25 mai 2018.
        </p>
        <p>2. Accès au Site</p>
        <p>
          Le Site est destiné à être utilisé uniquement par des personnes âgées
          d’au moins 18 ans. En utilisant le Site, vous nous confirmez que vous
          répondez à cette exigence et que, si vous avez moins de 18 ans, vous
          avez reçu la permission de votre parent ou tuteur avant d’utiliser le
          Site.
        </p>
        <p>
          Il est de votre responsabilité de vous assurer que votre équipement
          (ordinateur, ordinateur portable, netbook, tablette ou autre appareil
          mobile) répond à toutes les spécifications techniques nécessaires pour
          vous permettre d’accéder et d’utiliser le Site et qu’il est compatible
          avec ce dernier.
        </p>
        <p>
          Nous pouvons, de temps à autre, limiter l’accès à certaines
          caractéristiques, parties ou contenu du Site, ou à l’ensemble du Site,
          aux utilisateurs qui se sont inscrits auprès de nous. Vous devez vous
          assurer que tous les détails d’enregistrement que vous fournissez sont
          exacts. Si vous choisissez, ou si l’on vous fournit, un identifiant de
          connexion (tel qu’un nom d’utilisateur et un mot de passe ou tout
          autre identifiant) dans le cadre de nos procédures de sécurité, vous
          devez traiter ces informations comme confidentielles et ne les
          divulguer à personne. Vous êtes responsable de toutes les activités
          qui se déroulent sous votre identifiant de connexion et vous devez
          nous informer immédiatement de toute utilisation non autorisée ou
          autre violation de la sécurité dont vous avez connaissance. Nous nous
          réservons le droit de désactiver tout identifiant de connexion, à tout
          moment, si, à notre avis, vous n’avez pas respecté l’une des
          dispositions des présentes conditions ou si les informations que vous
          fournissez dans le cadre de votre inscription en tant qu’utilisateur,
          s’avèrent fausses.
        </p>
        <p>
          Nous ne pouvons garantir le fonctionnement continu, ininterrompu ou
          sans erreur du Site. Il peut arriver que certaines caractéristiques,
          parties ou contenu du Site, ou l’ensemble du Site, deviennent
          indisponibles (que ce soit sur une base planifiée ou non) ou soient
          modifiés, suspendus ou retirés par nous, à notre seule discrétion,
          sans préavis. Vous convenez que nous ne serons pas responsables envers
          vous ou envers un tiers de toute indisponibilité, modification,
          suspension ou retrait du Site, ou de toute caractéristique, partie ou
          contenu du Site.
        </p>
        <p>3.Ce que vous avez le droit de faire</p>
        <p>
          Vous ne pouvez utiliser le Site qu’à des fins non commerciales et
          conformément aux présentes conditions. Vous pouvez récupérer et
          afficher le contenu du Site sur un écran d’ordinateur, imprimer et
          copier des pages individuelles et, sous réserve de la section
          suivante, stocker ces pages sous forme électronique. Des conditions
          supplémentaires peuvent également s’appliquer à certaines
          caractéristiques, parties ou contenus du Site et, le cas échéant,
          elles seront affichées à l’écran ou accessibles via un lien.
        </p>
        <p>4. Ce que vous n’avez pas le droit de faire</p>
        <p>
          Vous ne devez utiliser le Site et tout ce qui est disponible sur le
          Site qu’à des fins légales (conformément à toutes les lois et
          réglementations applicables), de manière responsable, et non d’une
          manière qui pourrait nuire à notre nom ou à notre réputation ou à
          celle de l’une de nos filiales.
        </p>
        <p>
          Sauf dans la mesure expressément prévue dans les présentes conditions,
          vous n’êtes pas autorisé à:
        </p>
        <ul
          sx={{
            listStyle: "square",
            fontSize: "16px",
            li: { paddingBottom: "10px" },
          }}
        >
          <li>
            gratter ” le contenu du Site ou le stocker sur un serveur ou tout
            autre dispositif de stockage connecté à un réseau ou créer une base
            de données électronique en téléchargeant et en stockant
            systématiquement l’ensemble du contenu du Site ;
          </li>
          <li>
            supprimer ou modifier tout contenu du Site ou tenter de contourner
            la sécurité ou d’interférer avec le bon fonctionnement du Site ou
            des serveurs sur lesquels il est hébergé ; ou bien
          </li>
          <li>
            créer des liens vers le Site à partir de tout autre site Web, sans
            notre consentement écrit préalable, bien que vous puissiez créer un
            lien à partir d’un site Web que vous exploitez à condition que le
            lien ne soit pas trompeur et qu’il indique honnêtement sa
            destination, que vous n’impliquiez pas que nous vous approuvons,
            votre site ou tout produit ou service offert par vous, que vous
            créiiez un lien vers la page d’accueil du Site (et ne l’encadriez
            pas ou ne le reproduisiez pas), et le site Web lié n’ait aucun
            contenu illégal, menaçant, abusif, diffamatoire, pornographique,
            obscène, vulgaire, indécent, insultant ou qui viole les droits de
            propriété intellectuelle ou autres droits d’un tiers.
          </li>
        </ul>
        <p>
          Tous les droits qui vous sont accordés en vertu des présentes
          conditions prendront fin immédiatement si vous ne respectez pas l’une
          d’entre elles.
        </p>
        <p>5. Droits de propriété intellectuelle</p>
        <p>
          Tous les droits de propriété intellectuelle sur tout contenu du Site
          (y compris les textes, graphiques, logiciels, photographies et autres
          images, vidéos, sons, marques de commerce et logos) sont notre
          propriété ou celle de nos concédants de licence. Sauf ce qui est
          expressément stipulé ici, rien dans les présentes conditions ne vous
          donne de droits à l’égard de toute propriété intellectuelle
          appartenant à nous ou à nos concédants de licence et vous reconnaissez
          que vous n’acquérez aucun droit de propriété en téléchargeant le
          contenu du Site. Dans le cas où vous imprimez, copiez ou stockez des
          pages du Site (uniquement dans les limites autorisées par les
          présentes conditions), vous devez vous assurer que tout droit
          d’auteur, marque déposée ou autre droit de propriété intellectuelle
          compris dans le contenu original est reproduit.
        </p>
        <p>6. Caractéristiques et contenu du site</p>
        <p>
          Nous pouvons modifier le format, les caractéristiques et le contenu du
          site de temps à autre. Vous convenez que votre utilisation du site se
          fait ” en l’état ” et ” selon la disponibilité ” et à vos propres
          risques.
        </p>
        <p>
          Bien que nous nous efforcions de nous assurer que le contenu du Site
          constitué d’informations dont nous sommes la source est correct, vous
          reconnaissez que le Site peut mettre à disposition un contenu
          provenant de plusieurs sources, dont nous ne sommes pas responsables.
          Dans tous les cas, les informations du Site ne sont pas censées
          constituer une autorité ou un conseil sur lequel il convient de se
          fier. Vous devriez vérifier auprès de nous ou de la source
          d’information pertinente avant d’agir sur la base de ces informations.
        </p>
        <p>
          Nous ne faisons aucune déclaration et ne donnons aucune garantie quant
          à l’exactitude, l’exhaustivité, l’actualité, l’exactitude, la
          fiabilité, l’intégrité, la qualité, l’aptitude à l’emploi ou
          l’originalité de tout contenu du Site. Dans toute la mesure permise
          par la loi, toutes les garanties, conditions ou autres modalités de
          toute nature sont exclues et nous ne pouvons accepter de
          responsabilité pour toute perte ou tout dommage subi résultant de
          votre utilisation du Site ou de son utilisation qui s’appuie sur
          n’importe quel aspect de son contenu.
        </p>
        <p>
          Nous ne pouvons pas garantir ni ne garantissons que le contenu du Site
          sera exempt de virus et/ou d’autres codes qui pourraient contenir des
          éléments contaminants ou destructeurs. Il est de votre responsabilité
          de mettre en œuvre les mesures de sécurité informatique appropriées (y
          compris les antivirus et autres contrôles de sécurité) pour répondre à
          vos exigences particulières en matière de sécurité et de fiabilité du
          contenu.
        </p>
        <p>7. Contenu utilisateur</p>
        <p>
          Le Site peut, de temps à autre, vous permettre de télécharger du
          contenu généré par les utilisateurs et peut également vous permettre
          de communiquer ce contenu, soit à des destinataires sélectionnés, soit
          dans des zones publiques, telles que des pages de commentaires, des
          espaces de discussion, des tableaux de messages et/ou des groupes de
          discussion (collectivement les zones de contenu utilisateur). Nous ne
          contrôlons pas le matériel soumis aux zones de contenu utilisateur
          (collectivement, les zones de contenu utilisateur), et les zones de
          contenu utilisateur ne sont pas activement modérées. Vous êtes seul
          responsable du contenu de vos soumissions d’utilisateur telles que
          vous les avez soumises et vous reconnaissez que toutes les soumissions
          d’utilisateur expriment les opinions de leurs auteurs respectifs, et
          non les nôtres.
        </p>
        <p>
          Si vous participez à des zones de contenu utilisateur, vous devez
          conserver toutes les soumissions d’utilisateur pertinentes au but de
          la zone de contenu utilisateur et à la nature de tout sujet.
        </p>
        <p>Les soumissions de l’utilisateur ne doivent pas :</p>
        <ul
          sx={{
            listStyle: "square",
            fontSize: "16px",
            li: { paddingBottom: "10px" },
          }}
        >
          <li>
            contenir, transmettre, distribuer, établir des liens ou rendre
            disponible, ou faire de la publicité ou promouvoir tout contenu qui
            viole les droits de la propriété intellectuelle ou de la protection
            des données, la vie privée ou d’autres droits de toute autre
            personne, est diffamatoire ou viole toute obligation contractuelle
            ou de confiance, est obscène, sexuellement explicite, menaçant,
            abusif, harcelant, incitant à la violence ou à la haine,
            blasphématoire, discriminatoire (pour quel que motif que ce soit),
            susceptible de causer de l’anxiété, de l’inquiétude ou de
            l’embarras, sciemment faux ou trompeur, ou qui n’est pas conforme
            aux lois et règlements applicables ou autrement inacceptable (”
            contenu interdit “) ;
          </li>
          <li>
            usurper l’identité d’une personne ou d’une entité ou présenter de
            façon inexacte votre relation avec une personne ou une entité ;
          </li>
          <li>
            contenir, transmettre ou distribuer tout matériel publicitaire,
            marketing ou promotionnel non sollicité ou non autorisé ou toute
            autre forme de sollicitation (spam) ; ou
          </li>
          <li>
            transmettre ou distribuer tout virus et/ou autre code contenant des
            éléments contaminants ou destructeurs.
          </li>
        </ul>
        <p>
          Vous convenez qu’en soumettant une soumission d’utilisateur, vous nous
          accordez, à nous et à nos entités affiliées, un droit et une licence
          perpétuels, irrévocables, mondiaux, non exclusifs, libres de
          redevances et entièrement sous-licenciables pour utiliser, reproduire,
          modifier, adapter, publier, traduire, créer des travaux dérivés,
          distribuer, exécuter et afficher cette soumission (en tout ou partie)
          et/ou pour l’intégrer dans d’autres travaux sous toute forme, média ou
          technologie, et vous renoncez à tout droit moral sur cette soumission
          ou pour être identifié en tant qu’auteur de celle-ci.
        </p>
        <p>
          Bien que nous ne présélectionnions pas les soumissions d’utilisateur,
          nous nous réservons le droit, à notre seule discrétion, de supprimer,
          éditer ou modifier toute soumission d’utilisateur soumise par vous
          et/ou de fermer tout sujet, à tout moment et sans préavis.
        </p>
        <p>
          Les plaintes concernant le contenu de toute soumission d’utilisateur
          doivent être envoyées à info@sahajmarg.org et doivent contenir les
          détails de la soumission spécifique de l’utilisateur à l’origine de la
          plainte.
        </p>
        <p>8. Liens externes</p>
        <p>
          Le Site peut, de temps à autre, inclure des liens vers des sites
          externes, qui peuvent inclure des liens vers des offres et promotions
          de tiers. Nous les incluons pour vous donner accès à des informations,
          des produits ou des services que vous pourriez trouver utiles ou
          intéressants. Nous ne sommes pas responsables du contenu de ces sites
          ou de quoi que ce soit fourni par eux et ne garantissons pas qu’ils
          seront disponibles en permanence. Le fait que nous incluons des liens
          vers ces sites externes n’implique aucune approbation ou association
          avec leurs opérateurs ou promoteurs.
        </p>
        <p>9. Général</p>
        <p>
          Rien dans ces conditions ne limite ou n’exclut notre responsabilité
          envers vous :
        </p>
        <ul
          sx={{
            listStyle: "square",
            fontSize: "16px",
            li: { paddingBottom: "10px" },
          }}
        >
          <li>
            en cas de décès ou de dommages corporels causés par notre négligence
            ;
          </li>
          <li>pour fausse déclaration frauduleuse ;</li>
          <li>
            pour toute autre responsabilité qui, selon la loi, ne peut être
            limitée ou exclue.
          </li>
        </ul>
        <p>
          Sous réserve de ce qui précède, nous ne pouvons en aucun cas être
          tenus responsables envers vous des pertes commerciales. Si vous êtes
          un utilisateur commercial, nous ne pouvons en aucun cas être tenus
          responsables envers vous des pertes indirectes ou consécutives, ou de
          toute perte de profit, de revenus, de contrats, de données, de
          survaleur financière ou autres pertes similaires, et notre
          responsabilité pour les pertes que vous pourriez subir est strictement
          limitée aux pertes qui étaient raisonnablement prévisibles.
        </p>
        <p>
          Les présentes conditions sont régies par le droit suisse (si vous êtes
          un consommateur et non un utilisateur professionnel).
        </p>
        <p>
          Vous acceptez que tout litige entre vous et nous concernant les
          présentes conditions ou le Site sera traité uniquement par les
          tribunaux suisses (si vous êtes un consommateur et non un utilisateur
          commercial) mais si nous intentons des poursuites judiciaires, nous ne
          pourrons le faire que dans votre pays.
        </p>
        <p>10. Nous contacter</p>
        <p>
          Si vous avez des questions au sujet de ces conditions ou si vous avez
          des problèmes concernant le Site, veuillez-nous les soumettre par
          courriel à <a href="mailto:info@sahajmarg.org">info@sahajmarg.org</a>{" "}
          ou par écrit à l’adresse de messagerie suivante :
        </p>{" "}
        <a href="mailto:ch.treasurer@sahajmarg.org" sx={{ color: "#333" }}>
          ch.treasurer@sahajmarg.org
        </a>
      </Box>
    </LayoutHome>
  );
};

export default Conditions;
